import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactUsViewVue from "@/views/ContactUsView.vue";

const routes = [

  {
		path: '/',
		name: 'home',
		component: HomeView
	},
  {
    path: "/contactus",
    name: "contactus",
    component: ContactUsViewVue
  }
  // { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
