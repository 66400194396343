<template>
    <div>
      <div class="counter-section theme-background-2  pb-90">
         <div class="container">
            <div class="counter-border">
               <div class="row">
                  <div v-for="item in counterListing"  :key="item.title" :class="{leftBorder: item.border == 'both' }" class="col-lg-4 col-md-4 col-sm-12 counter-item-spacing desktop-only">
                     <div  :class="{ rightBorder: item.border == 'right'||  item.border == 'both'}"  class="counter-item d-flex align-items-center mb-30">
                        <div class="counter-icon">
                           <span class="counter-icon-sizing"><font-awesome-icon :icon="item.icon" /></span>
                        </div>
                        <div class="bd-counter__info">   
                           <span class="counter"> {{item.count}}</span> 
                           <h3 class="counter-title"> {{item.title}}</h3>
                        </div>
                     </div>
                  </div>
                  <AutoSwiperVue>
                     <div v-for="item in counterListing"  :key="item.title" class=" col-md-12 counter-item-spacing  swiper-slide mobile-only">
                        <div class="counter-item d-flex align-items-center mb-30">
                           <div class="counter-icon">
                              <span class="counter-icon-sizing"><font-awesome-icon :icon="item.icon" /></span>
                           </div>
                           <div class="bd-counter__info">
                              <span class="counter"> {{item.count}}</span>
                              <h3 class="counter-title"> {{item.title}}</h3>
                           </div>
                        </div>
                     </div>
                  </AutoSwiperVue>
               </div>
            </div>
         </div>
      </div>
    </div>
</template>
<script>
import AutoSwiperVue from '../components/AutoSwiper.vue';

  export default {
   name: 'Statistics',
   components: {
      AutoSwiperVue
   },
     computed: {
      counterListing() {
         return [
               {
                title: this.$t('Years'),
                icon:'fa-solid fa-gear',
               count: '15',
                border:'both'
             },
             {
                title: this.$t('Clients'),
                icon:'fa-solid fa-user-tie',
                count: '96',
                border:'right'

             },
             {
                title: this.$t('Patents'),
                icon:'fa-solid fa-trophy',
                count: '30 +',
                border:'right'

             },
        ]
      }
    }
  }
</script>

<style scoped>
      @media only screen 
      and (max-device-width : 991px) 
       {
            .counter-item-spacing{
                  margin-bottom: 2rem;
            }
      }

         .counter-item-spacing{
                  margin-bottom: 2rem;
            }
      .counter{
         font-size: 1.2rem;
      }
   .counter-section{
      padding-top:100px;
      padding-bottom: 100px;
   }
   .counter-title{
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 4px;
   }
   .counter-item{
      margin-left: 26%;
   }


   .counter-icon{
      margin-right: 20px;
   }
   .counter-icon-sizing{
          font-size: 2.6rem;
   }
   
</style>