<template>
  <div class="about">
    <Banner :pageName="$t('Contact')"/>
    <ContactForm/>
  </div>
</template>
<script>
  import Banner from "@/components/PageBanner.vue";
  import ContactForm from "@/components/Contact/ContactForm.vue";

  export default {
    name: "ContactUsView",
    components: {
      Banner,
      ContactForm,
    },
  };
  
</script>