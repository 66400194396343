<template>
   <div class="container my-5">
      <div class="row theme-bg p-4 align-items-center">
         <div class="col-lg-9 col-sm-12">
            <div class="align-text-left">
               <h4 class="contact-info-type">{{ $t('Address')}}</h4>
               <p class="mb-0">Viale dell'Arte, 38 <br/>
                  00144 Roma RM<br/>
                  Italy </p>
            </div>
         </div>
         <!-- <div class="col-lg-5 col-sm-12"></div> -->
         <!--add css for mobile-->
         <div class="col-lg-3 col-sm-12 my-4">
            <h4 class="contact-info-type">{{ $t('VisitUs')}}</h4>
            <a class="external-link" href="https://www.linkedin.com/company/beyond-ita/">
               <img src="../../assets/linkedin.png" style="height:2rem" alt="LinkedIn">
            </a>
         </div>
      </div>
      <br>
      <div class="row map">
         <iframe class="px-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.9200042841017!2d12.471724512507919!3d41.83002067112719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13258b05a2a0b42f%3A0x536b12a3a524e56f!2sBeyond%20it!5e0!3m2!1sit!2sit!4v1706119032815!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
      </div>
   </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { ref } from 'vue';
import axios from 'axios'
import ConfirmationModal from '../ConfirmationModal.vue';
export default {
   name: 'ContactForm',
   components: [
      ConfirmationModal
   ],
   setup() {
      const modalOpen = ref(true)

      const toggleModal = () => {
         modalOpen.value = !modalOpen.value
      }
      return {modalOpen , toggleModal}
   },
   props: {
    pageName: String
   },
   data() {
      return {
         v$: useValidate(),
         name: '',   
         email: '',
         subject: '',
         message: '',
      }
   },
   methods: {
      submitForm() {
         this.v$.$validate() // checks all inputs
         if (this.v$.$error) {
         // if ANY fail validation
               console.log("THIS IS AN ERROR ");
         } 
         const emailSubmitEndpoint = 'https://api.emailjs.com/api/v1.0/email/send'
         const templateData = {
            from_name: this.name,
            from_email: this.email,
            subject: this.subject,
            message: this.message
         }
         
         const data = {
            service_id: 'service_uo9a95u',
            template_id: 'template_uo5npdg',
            user_id: 'CBBcTKqbcepzUwJh8',
            template_params:templateData
         };
         axios.post(emailSubmitEndpoint, data)
         .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
            alert("Success")
         }).catch(function (error) {
            console.log('FAILED...', error);
            alert("Failure")
         });

         // Reset form field
         // this.name = ''
         // this.email = ''
         // this.subject = ''
         // this.message = ''
      }
   },
   validations() {
      return {
         name:{required},   
         email: {required},   
         subject:{required},   
      }
   }
}
 
</script>

<style scoped>
   .contact-form {
      margin-bottom: 20px;
      padding: 40px 30px;
      box-shadow: 0 10px 30px 0 rgb(0 0 0 / 9%);
      background: white;
   }

   .contact-form-title {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 40px;
   }

   .contact-form-input {
      width: 100%;
   }

   .contact-info {
      padding-bottom: 10px;
   }

   input, textarea {
      height: 55px;
      padding: 0 20px;
      width: 100%;
      font-size: 14px;
      color: black;
      outline: none;
      border: 1px solid transparent;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      -o-border-radius: 7px;
      -ms-border-radius: 7px;
      border-radius: 7px;
      background: #f7f7f7;
   }

   .contact-text-area textarea {
      height: 175px;
      resize: none;
      padding-top: 20px;
      padding-bottom: 20px;
   }

   input::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
      /*styles here*/
      font-size: 16px;
      font-weight: 500;
   }

   textarea::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
      /*styles here*/
      font-size: 16px;
      font-weight: 500;
   }
</style>