<template>
    <div class="container section-spacing">
      <div class="section-header pb-5">
          <h2 class="pb-2 section-title">{{$t("OurCompetencies")}}</h2>
      </div>
      <div class="row">
         <AutoSwiperVue>
             <div v-for="item in servicesListing" :key="item.title" class="col-lg-4 swiper-slide mobile-only" >
            <div class="services-card-item text-center mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="services-card-title">
                  <h3 class="section-title ">
                  <div class="nav-link text-uppercase"> {{item.title}} </div>
                  </h3>
              </div>
               <div class="services-card-icon text-center">
                  <font-awesome-icon :icon="item.icon" />
               </div>
               <div class="bd-services-content">
                  <p> {{item.description}}</p>
               </div>
            </div>
         </div>
         </AutoSwiperVue>

         <div v-for="item in servicesListing" :key="item.title" class="col-lg-4 desktop-only" >
            <div class="services-card-item text-center mb-30 wow fadeInUp bg-white" style="visibility: visible; animation-name: fadeInUp;">
              <div class="services-card-title">
                  <h3 class="section-title ">
                     <!-- <router-link  to=""></router-link> -->
                     <div class="nav-link text-uppercase"> {{item.title}} </div>
                  </h3>
              </div>
               <div class="services-card-icon text-center">
                  <font-awesome-icon :icon="item.icon" />
               </div>
               <div class="bd-services-content">
                  <p> {{item.description}}</p>
               </div>
            </div>
         </div>
        
       </div>
    </div>
</template>
<script>
import AutoSwiperVue from '../AutoSwiper.vue';

export default {

  name: "Services",
   data() {
    return {
    }
   },
   components: {
      AutoSwiperVue
   },
   computed: {
      servicesListing() {
         return [
               {
                title: this.$t('WebDevelopment'),
                description: this.$t('ServicesDescription1'),
                icon:'fa-solid fa-code',
                link: '/serviceDetails'
             },
             {
                title: this.$t('CustomApplications'),
                description: this.$t('ServicesDescription2'),
                icon:'fa-solid fa-table',
                link: '/serviceDetails'
             },
             {
                title: this.$t('DevOps'),
                description: this.$t('ServicesDescription5'),
                icon:'fa-solid fa-pen-nib',
                link: '/serviceDetails'
             },
        ]
      }
    }
  }
</script>
<style scoped>
   .services-content{
      color:#888888 !important;
   }
   
   
</style>
