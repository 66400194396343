<template>
    <div class="container-fluid py-4 px-5 theme-bg">
      <div class="row">
         <h5 class="mb-4 mt-2 text-center">{{$t('WhyChooseUsSubtitle')}}</h5>
      </div>
      <div class="row">
         <div v-for="item in servicesListing" :key="item.title" class="col-xl-3 col-md-6 col-sm-12 my-4 desktop-only" >
            <div class="services-card-item text-center fadeInUp bg-white" style="visibility: visible; animation-name: fadeInUp;">
               <div class="services-card-title">
                  <h4 class="section-title"> 
                     <div class="nav-link text-uppercase truncate"> {{item.title}} </div>
                  </h4>
               </div>
               <div class="services-card-icon text-center">
                  <font-awesome-icon :icon="item.icon" />
               </div>
               <div class="bd-services-content">
                  <p> {{item.description}}</p>
               </div>
            </div>
         </div>
      <AutoSwiperVue>
         <div v-for="item in servicesListing" :key="item.title" class="col-lg-4  col-sm-12  mb-4 swiper-slide mobile-only" >
            <div class="services-card-item text-center fadeInUp bg-white" style="visibility: visible; animation-name: fadeInUp;">
               <div class="services-card-title">
                  <h3 class="section-title">
                     <div class="nav-link text-uppercase truncate"> {{item.title}} </div>
                  </h3>
               </div>
               <div class="services-card-icon text-center">
                  <font-awesome-icon :icon="item.icon" />
               </div>
               <div class="bd-services-content">
                  <p> {{item.description}}</p>
               </div>
            </div>
         </div>
      </AutoSwiperVue>
      </div>
    </div>
</template>
<script>
import AutoSwiperVue from '../AutoSwiper.vue';

export default {  
  name: "ServiceCards",
   data() {
    return {
      }
   },
   components: {
      AutoSwiperVue
   },
   computed: {
      servicesListing() {
         return [
            {
               title: this.$t('WebDevelopment'),
               description: this.$t('ServicesDescription1'),
               icon:'fa-solid fa-code'
            },
            {
               title: this.$t('CustomApplications'),
               description: this.$t('ServicesDescription2'),
               icon:'fa-solid fa-table'
            },
            {
               title: this.$t('DevOps'),
               description: this.$t('ServicesDescription4'),
               icon:'fa-solid fa-terminal'
            },
            {
               title: this.$t('Consultancy'),
               description: this.$t('ServicesDescription3'),
               icon:'fa-solid fa-pen-nib'
            }
         ]
      }
   }
}
</script>

<style scoped>
   .services-content {
      color:#888888 !important;
   }
   .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
   }
</style>
