<template>
<div>
  <!-- Navigation  -->
    <nav class="navbar navigation-bar sticky-top navbar-expand-lg">
        <div class="container ">
          <router-link class="navbar-brand nav-custom" to="/">
            <img src="@/assets/logo-transparent.png" class="logo" alt="BeyondIT Logo">
          </router-link >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse row navbar-collapse navbar-menu-items" id="navbarText">
            <ul class="navbar-nav mb-2 mb-lg-0 py-2">
              <li class="nav-item">
                  <div> <router-link class="nav-link navigation-link text-uppercase" to="/">{{ $t('Home')}}</router-link></div>
              </li>
<!--              <li class="nav-item">-->
<!--                <div><router-link class="nav-link navigation-link text-uppercase" to="/services">{{ $t('Services')}}</router-link></div>-->
<!--              </li>-->
<!--                <li class="nav-item">-->
<!--                <router-link class="nav-link navigation-link text-uppercase" to="/careers">{{ $t('Careers')}}</router-link>-->
<!--              </li>-->
               <!-- <li class="nav-item">
                    <router-link class="nav-link  navigation-link text-uppercase" to="/about">{{ $t('About')}}</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link navigation-link text-uppercase" to="/contactus">{{ $t('Contact')}}</router-link>
              </li>
              <li  class="left-spacing">
                <div class="row g-2 align-items-center language-switch">
                    <div :class="{languageSelected:isItalian}" @click="toggleLang('it')" class="col-6 align-items-bottom language-select-box ">
                        <div class="lang-box"  >ITA</div>
                    </div>
                    <div :class="{languageSelected:isEnglish}"  @click="toggleLang('en')" class="col-6 align-items-bottom language-select-box">
                      <div :class="{langActive:isEnglish} " class="lang-box" >ENG</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </nav>
   <router-view v-slot="{Component }">
      <transition name="page" mode="out-in">
       <component :is="Component"/>
      </transition>
  </router-view>

    <!-- Footer section  -->
    <div class="footer-section">
      <div class="container">
        <footer class="py-2">
          <div class="row my-3">
            <div class="col-md-6 align-text-left col-sm-12 col-md-3">
              <img src="@/assets/logo-transparent.png" class="logo" alt="BeyondIT Logo">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 align-text-left">
              <p>© 2020 - 2024 {{ $t('BeyondIt') }} {{ $t('AllRightsReserved')}}</p>
            </div>
            <div class="col-md-6 col-sm-12 align-text-right mb-3 ">
              <p>{{ $t('IdentificationNumber')}}</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
</div>
</template>

<script>
import HomepageBanner from '@/components/Homepage/Banner.vue'
export default {
  name: "Layout",
  components: {
      HomepageBanner
  },
  data() {
    return {
      lang: 'en',
      }
  },
  methods: {
    toggleLang(lang) {
          this.$i18n.locale = lang
          this.lang = lang
          return
    }
  },
  computed: {
    isEnglish() {
      return this.lang == 'en'? true : false
    },
    isItalian() {
     return this.lang == 'it'? true : false
    }
  }
}
</script>

<style scoped>

  @media only screen
  and (min-width: 992px)
  and (max-width: 1200px)
  {
      .navbar-menu-items{
        margin-left: 7%;
      }
       .nav-item{
        margin-right: 1.5rem;
        transition: 0.5s ease-in;
      }
   }
  @media only screen
  and (min-width: 1200px)
  /* and (max-width: )  */
  {
      .navbar-menu-items{
        margin-left: 15%;
      }
       .nav-item{
        margin-right: 2.2rem;
        transition: 0.5s ease-in;
      }
   }


  @media only screen
    and (max-device-width : 991px) {
      .left-spacing{
        margin: auto !important;
      }
  }
  .left-spacing{
        margin-left: auto;
  }
  .nav-item a {
    box-sizing: border-box;
  }

  .navigation-bar {
    transition: 1s ease;
    background-color: #e2ebf4 !important;
  }
  .page-enter-from,
  .fade-leave-to{
    opacity: 0;
  }
  .page-enter-active,
  .fade-leave-active{
    transition: opacity 0.5s ease;
  }

  .router-link-exact-active{
    background-color:#fcce24;
    color: black;
    border-radius: 5px;
    transition: 0.3s ease-in;
  }

    .nav-link {
      transition: 0.3s ease;
      color: #3D3D3D;
    }
    .navigation-link{
      border: 1px solid #e2ebf4;
    }
   .navigation-link:hover {
      border: 1px solid #fcce24;
      border-radius: 5px;
    }
    .nav-link.active {
      color: white;
    }
    .logo{
      width: 95px;
      padding: 4px;
    }
    .footer-section{
      background-color: #e2ebf4;
    }
   .languageSelected{
      border: none;
      background-color: #fbc912;
    }
    .langActive{
      position: relative;
      right: 4px;
    }

    .nav-custom{
      background-color: #e2ebf4;
      border: none;
    }
    .language-switch{
      width:7rem;
      cursor: pointer;
    }

    .language-select-box{
      border: 1px solid #e2ebf4 ;
      border-radius: 5px;
    }
    .language-select-box:hover{
      border: 1px solid #fbc912 ;
      border-radius: 5px;
      transition: 1s ease;
    }

    .footer-rights{
      font-size: 12px;
    }
    .lang-box{
      margin:10px;
      color: #3D3D3D;
    }
</style>