<template>
        <div class="hero" >
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                    <p class="title-text text-start text-uppercase pb-2">{{$t('Slogan')}} </p>
                    <div class="cta-button d-flex">
                      <div class="text-lg-end wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                        <router-link to="/contactus" class="border-btn fw-bold">
                          {{ $t('ContactUs')}}
                          <font-awesome-icon icon="fa-solid fa-chevron-right" />
                        </router-link>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { ref } from 'vue'; 
export default {
  name: 'HomepageBanner',
  }
</script>

<style scoped>
  @media only screen 
  and (min-device-width : 320px) 
  and (max-device-width : 480px) {
    .hero{
      padding: 28% 14px !important;
    }
    .title-text{
      font-size: 8vw !important;
    }
  }

.hero {
  background: url("@/assets/background-1.jpg") bottom right no-repeat;
  background-size: cover;
  padding: 6% 0 15% 0;
}

.header-text{
    font-size: 28px;
    font-weight: 500;
    color: black;
}

.title-text{
  font-size: 3vw;
  color: black;
  text-transform: uppercase;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-btn:hover{
  color: #0660bd;
}



</style>
