<template>
  <div class="home">
      <Banner />
      <AboutUs />
<!--      <Counter />-->
      <div class="theme-bg">
        <ServiceCards/>
      </div>
      <WhyChooseUs />
      <Cta />
      <Brands/>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Homepage/Banner.vue";
import Services from "@/components/Homepage/Services.vue";
import AboutUs from "@/components/Homepage/AboutUs.vue";
import Counter from "@/components/Counter.vue";
// import Portfolio from "@/components/Homepage/Portfolio.vue";
// import Testimonials from "@/components/Homepage/Testimonials.vue";
import Cta from '@/components/Cta.vue';
import Brands from "@/components/Brands.vue";
import WhyChooseUs from "@/components/About/WhyChooseUs.vue";
import ServiceCards from "@/components/Services/ServiceCards.vue";

export default {
  name: "HomeView",
  components: {
    Banner,
    Services,
    Counter,
    // Portfolio,
    // Testimonials, 
    ServiceCards,
    AboutUs,
    Cta,
    Brands,
    WhyChooseUs
},
};
</script>
