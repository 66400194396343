<template>
    <div v-show="modalActive" >
  <!--  <div v-show="modalActive" class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
       <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body" style="color:red">
            <slot/>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div> -->
    </div>
</template>
<script>
export default {
  name: 'ConfirmationModal',
  props: [
    'modalActive'
  ],
  data() {
    return {
      }
  }
}
</script>

<style scoped>

</style>
