import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/main.css";
import "../node_modules/vue3-toggle-button/dist/style.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import en from "@/locales/en.json";
import it from "@/locales/it.json";
import { Vue3ToggleButton } from "vue3-toggle-button";
import { createI18n } from "vue-i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import {
  faBuilding,
  faChevronRight,
  faCloud,
  faCode,
  faCompassDrafting,
  faGear,
  faGlobe,
  faHandshake,
  faLayerGroup,
  faLightbulb,
  faMobileScreen,
  faPenNib,
  faShieldHalved,
  faTable,
  faTerminal,
  faTrophy,
  faUniversalAccess,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/js/bootstrap.js";

library.add(
  faChevronRight,
  faTable,
  faCode,
  faPenNib,
  faGear,
  faUserTie,
  faLayerGroup,
  faTrophy,
  faBuilding,
  faCloud,
  faMobileScreen,
  faShieldHalved,
  faGlobe,
  faCompassDrafting,
  faHandshake,
  faLightbulb,
  faUniversalAccess,
  faTerminal
);

const i18n = createI18n({
    messages: {
      en: en,
      it: it
    },
    fallbackLocale: "en"
  }
);

createApp(App).use(store).use(router).use(i18n).use(Vue3ToggleButton)
  // .use(AOS.init())
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Vue3ToggleButton", Vue3ToggleButton)
  .mount("#app");

