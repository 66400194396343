<template>
   <div class="container my-5">
      <h5 class="mb-5 text-center">{{ $t('WhyChooseUsTitle') }}</h5>
      <div class="row g-2">
         <div class="col-md-6 col-sm-12" v-for="item in reasonsListing" :key="item.title">
            <div class="d-flex align-items-center h-100 service-card">
               <div class="py-4 my-4 rounded-start h-100 bg-yellow">
                  <div class="service-icon">
                     <span class="fs-5"><font-awesome-icon :icon="item.icon" /></span>
                  </div>
               </div>
               <h5 class="px-4 my-4 pt-2 fs-6 text-start">
                  <h3 class="feature-title">{{item.title}}</h3>
                  <p>{{item.description}}</p></h5>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: 'AboutUs', 
  computed: {
    reasonsListing() {
      return [
             {
                title: this.$t('Security'),
                description: this.$t('AboutDescription1'),
                icon:'fa-solid fa-shield-halved',
             },
              {
                title: this.$t('Innovation'),
                description: this.$t('AboutDescription2'),
                icon:'fa-solid fa-lightbulb',
              },
              {
                title:  this.$t('InternationalCulture'),
                description: this.$t('AboutDescription3'),
                icon:'fa-solid fa-globe',
               },
              {
                title:  this.$t('Cloud'),
                description: this.$t('AboutDescription4'),
                icon:'fa-solid fa-cloud',
               },
                  {
                title: this.$t('NoBarriers'),
                description: this.$t('AboutDescription5'),
                icon:'fa-solid fa-universal-access',
             },
              {
                title:  this.$t('TransparencyAndTrust'),
                description: this.$t('AboutDescription6'),
                icon:'fa-solid fa-handshake',
              },
              {
                title:  this.$t('SoftwareOnTheGo'),
                description:this.$t('AboutDescription7'),
                icon:'fa-solid fa-mobile-screen',
               },
              {
                title:  this.$t('SolidDesign'),
                description:this.$t('AboutDescription8'),
                icon:'fa-solid fa-compass-drafting',
              },
        ]
      }
    }
  }
</script>
<style scoped>

   .bg-yellow {
      background-color: #fbc912;
   }

   .service-card {
      border: 1px solid #f2f2f2;
      transition: 1s;
      border-radius: 5px;
   }

   .service-icon {
      width: 4rem; 
      height: inherit;
   }

   @media only screen 
      and (min-device-width : 320px) 
      and (max-device-width : 480px) {
            .feature-icon{
               width: 3rem;
               height: 3rem;
                line-height: 3rem;
                text-align: center;
                margin-right: 1rem;

            }
      }

   .about-card{
      border: 1px solid #f2f2f2;
      transition: 1s;
      border-radius: 5px;
      padding: 10px;

   }
   .about-card:hover{
      border: 1px solid #eaeced;
      box-shadow: 8px 8px 6px -8px rgba(0,0,0,0.71);
      -webkit-box-shadow: 8px 8px 6px -8px rgba(0,0,0,0.71);
      -moz-box-shadow: 8px 8px 6px -8px rgba(0,0,0,0.71);
   }
</style>