<template>
   <div class="cta_section primary-bg">
      <div class="mx-4 px-4">
         <div class="row">
            <div class="col-md-8">
               <div class="cta-info text-start fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                  <h3 class="cta-title">{{ $t('Cta')}}</h3>
                  <span class="cta-subtitle" >{{ $t('CtaSubtitle')}}. </span>
               </div>
            </div>
            <div class="col-md-4 cta-button">
               <div class="text-lg-end wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                  <router-link to="/contactus" class="border-btn">
                     {{ $t('ContactUs')}}
                     <font-awesome-icon icon="fa-solid fa-chevron-right" />
                  </router-link>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name:'Cta'
}
</script>

<style>
   @media only screen 
   and (min-device-width : 320px) 
   and (max-device-width : 980px) {
         .cta-subtitle {
            margin-bottom: 2rem;
         }
         .cta-button{
            margin-top: 10px;
            text-align: start;
         }
   }

  .cta_section {
         background-color:#fbc912;
         padding-top: 60px;
         padding-bottom: 60px;
  } 

  .cta-title {
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 4px;
      text-transform: uppercase;
  }

  

  .cta-info {
      color: black;
      font-size: 18px;
      font-weight: 600;
  }
</style>