<template>
    <div>
      <div class="container my-5">
            <div class="row align-items-center">
               <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="fadeInRight" style="visibility: visible; animation-name: fadeInRight;">
                     <img class="about-image" src="@/assets/aboutus.jpg" alt="">
                  </div>
               </div>
               <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">
                     <div class="section-wrapper text-start py-3">
                        <h3 class="section-title section-title-lg mb-25 text-start about-section-title">{{ $t('WhoWeAre')}} <br> 
                        </h3>
                        <p class="text-start about-text">{{ $t('AboutUs1')}}</p>
                        <p class="mb-25 text-start about-text">{{ $t('AboutUs2')}}</p>
                        <p class="mb-25 text-start about-text">{{ $t('AboutUs3')}}</p>
                     </div>
                  </div>
               </div>
            </div>
      </div>
    </div>
</template>
<script>
export default {
    name:'AboutUs'
  }
</script>

<style scoped>

  @media only screen 
   and (min-device-width : 320px) 
   and (max-device-width : 480px) {
         .about-section-title{
            padding: 1rem 0;
         }
         .section-wrapper{
               padding: 5%;
          }  

   }
   
   .about-text{
      line-height: 2rem;
   }
   .about-image{
          width: 100%;
   }
</style>